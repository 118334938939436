// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  recaptchaSiteKey: '6LcaO68ZAAAAAN_GSy-3XcX5xnAHOMxmL_c67pPm',
  firebaseConfig : {
    apiKey: "AIzaSyBbYklcKebajEWtqvMpYPjAj3Ig9CTgQKc",
    authDomain: "project-contact42.firebaseapp.com",
    databaseURL: "https://project-contact42.firebaseio.com",
    projectId: "project-contact42",
    storageBucket: "project-contact42.appspot.com",
    messagingSenderId: "956395066402",
    appId: "1:956395066402:web:108976437c7781bbdf267b",
    measurementId: "G-M5QCYT38LE"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
