import { Component, OnInit } from '@angular/core';
import * as myGlobals from 'globals';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {
  intViewportHeight: number;

  constructor() { }

  ngOnInit(): void {
    this.intViewportHeight = myGlobals.intViewportHeight;
  }

}
