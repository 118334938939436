import * as firebase from 'firebase/app';
import 'firebase/functions';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WallpaperService {
  data: any;

  constructor() {}

  async getImage() {
    var unsplashImage = firebase.functions().httpsCallable('unsplashImage')
    var data = unsplashImage().then(res=> {return res}).catch(err=>{return err})
    // console.log("Unsplash Image data: ", data);
    return data;
  }
}
