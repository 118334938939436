
<nav class="navbar fixed-bottom navbar-expand-lg navbar-dark">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbars" aria-controls="navbars" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <!-- <div class="collapse navbar-collapse justify-content-md-center" id="navbars"> -->
    <div class="collapse navbar-collapse justify-content-md-center" id="navbars">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink=""><span class="material-icons">
            person
            </span> Home</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"  routerLink="/about"><span class="material-icons">
            text_snippet
            </span> About</a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/skills"><span class="material-icons">
            code
            </span> Skills</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/contact"><span class="material-icons">
            message
            </span> Contact</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/facts">Facts</a>
        </li> -->
        <!-- <li class="nav-item dropup">
          <a class="nav-link dropdown-toggle" href="#" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dropup</a>
          <div class="dropdown-menu" aria-labelledby="dropdown">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </li> -->
      </ul>
    </div>
  </nav>
