import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as myGlobals from 'globals';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Gurtej Gill';
  intViewportHeight: number;

  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-171740331-1',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    }
    )

    myGlobals.intViewportHeight = window.innerHeight - 145;
    this.intViewportHeight = myGlobals.intViewportHeight;
  }

}
