
    <div class="padding">
        <div *ngIf="!submitted else showSubmitted">
            <div class="">
                <form [formGroup]="contactForm">

                    <div class="row">
                    <div class="form-group col-md-6 col-sm-12">
                        <div class="">
                        <label for="name">Name</label>
                            <span *ngIf="contactForm.get('name').touched && contactForm.get('name').errors?.required" 
                            class="helper-text">&nbsp;required</span>
                    </div>
                        <input type="text" id="name" formControlName="name" class="validate " placeholder="Enter your name">
                    </div>
                    <div class="form-group col-md-6 col-sm-12">
                        <div class="">
                        <label for="email">Email</label>
                        <div *ngIf="contactForm.get('email').touched && contactForm.get('email').errors?.required">
                            <span class="helper-text">&nbsp;required</span>
                        </div>
                        <div *ngIf="contactForm.get('email').touched && contactForm.get('email').errors?.email">
                            <span class="helper-text">&nbsp;invalid</span>
                        </div>
                    </div>
                        <input type="email" id="email" formControlName="email" class="validate " placeholder="Enter valid email">
                    </div>
                </div>
                <div class="">
                    <div class="form-group">
                        <div class="">
                        <label for="subject">Subject</label>
                        <div *ngIf="contactForm.get('subject').touched && contactForm.get('subject').errors?.required">
                            <span class="helper-text">&nbsp;required</span>
                        </div>
                    </div>
                        <input type="text" id="subject" formControlName="subject" class="validate " placeholder="Enter subject here">
                    </div>
                </div>
                <div class="">
                    <div class="form-group">
                        <div class="">
                        <label for="message">Message</label>
                        <div *ngIf="contactForm.get('message').touched && contactForm.get('message').errors?.required">
                            <span class="helper-text">&nbsp;required</span>
                        </div>
                    </div>
                        <textarea type="text" id="message"  formControlName="message" class="validate " placeholder="Enter message here">

                        </textarea>
                    </div>
                </div>

                    <!-- <div class="progress" *ngIf="submitting">
                        <div class="indeterminate"></div>
                    </div> -->
                    <div class="form-group">

                        <div class=" validateRecaptcha">
                            <!-- 
                              use form control for validation
                            -->
                            <re-captcha (resolved)="resolved($event)" 
                                        formControlName="recaptchaReactive"
                                        theme="dark">  
                            </re-captcha>
                          </div>
                        </div>
                        <div class="">
                        <div class="">
                    <button type="submit" class="btn btn-secondary" value="Submit" [disabled]="contactForm.invalid" class="submit"
                        (click)="submitData(contactForm.value)">Submit</button>
                    </div>
                    </div>
                </form>
            </div>
        </div>

    </div>


<ng-template #showSubmitted>
    <h1 class="display-4">Message Sent</h1>
    <h1 class="display-5">I'll get back to you soon!</h1>
</ng-template>