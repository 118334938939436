import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { RecaptchaService } from '../recaptcha.service'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  // private submissionForm: AngularFirestoreCollection<any>;
  private submissionForm: any;

  contactForm: FormGroup;
  submitting = false;
  submitted = false;
  currentDateTime = new Date().getTime();
  recaptchaSuccess: boolean = undefined;

  constructor(private fb: FormBuilder,
    private recaptchaService: RecaptchaService
    ) { }

  ngOnInit(): void {
    this.submissionForm = firebase.firestore().collection('portfolioContact');
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      timestamp: [this.currentDateTime],
      recaptchaReactive: new FormControl(null, Validators.required)
    })
  }

  async submitData(value: any) {
    // console.log(this.submitted);
    // console.log(value);

    if (this.recaptchaSuccess) {
      this.submitting = true;
      this.submissionForm.add(value).then(res => {
        this.submitted = true;
      }).catch(err => console.log(err)
      ).finally(() => {
        this.submitting = false;
      });
    }
  }

  //function to resolve the reCaptcha and retrieve a token
  async resolved(captchaResponse: string, res) {
    //console.log(`ContactComponent Resolved response token: ${captchaResponse}`);
    await this.sendTokenToBackend(captchaResponse); //declaring the token send function with a token parameter
  }

  //function to send the token to the node server
  sendTokenToBackend(tok) {
    //calling the service and passing the token to the service
    this.recaptchaService.sendToken(tok)
      .then(res => {
        //console.log("ContactComponentsendTokenToBackend", res)
        this.recaptchaSuccess = res.data
      })
      .catch(err => {
        console.log("cc")
      }
      );
  }

}
