<!-- <div class="container-fluid">
  <div class="row">
<div class="accordion col-md-12" id="accordionExample">
    <div class="card">
      <div class="card-header" id="headingOne">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Inforica Inc.
          </button>
        </h2>
      </div>
  
      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <ul>Software Developer
            <li>Creating reusable components, Routing, Services and APIs in Angular</li>
            <li>Bug fixing and supporting code in C# .Net Core and Angular on ASP .Net Zero Framework</li>
            <li>Creating Stored Procedures, Tables and Views on SQL Server</li>
            <li>Development with HTML, CSS, Typescript, Bootstrap, JSON</li>
            <li>Maintaining Virtual Machines, Web Apps and working with Kusto Queries on Microsoft Azure</li>
            <li>Working with BPM tools to create data integration workflows</li>
            <li>Source code management using Team Foundation Server</li>
            <li>Technical analysis and determining requirementsSolving complex code problems and POC testing</li>
            </ul>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="headingTwo">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            Smart Employee Benefits Inc./SEB Administrative Inc.
          </button>
        </h2>
      </div>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
        <div class="card-body">
          <ul>Web Developer
            <li>Developing dynamic and static web pages using Javascript, HTML</li>
            <li>Developing, designing and maintaining multiple client websites</li>
            <li>Designing webpage layout using Bootstrap and CSS</li>
            <li>Working with various CMS like DNN, Wordpress, Shopify and Squarespace</li>
            <li>Working with reusable components in Angular 4, React</li>
            <li>Hosting Web Apps on Microsoft Azure and performing operations in SQL Server</li>
            <li>Solving code problems and researching current design trends</li>
            <li>Designing graphics, animations and manipulating digital photographs</li>
            <li>Training client's staff, providing technical support</li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</div>
</div> -->
<div class="content" [style.height.px]="intViewportHeight">

<!-- Grid row -->
<div class="row accordion-gradient-bcg d-flex justify-content-center">

  <!-- Grid column -->
  <div class="col-md-12 col-xl-12">

    <!--Accordion wrapper-->
    <div class="accordion md-accordion accordion-2" id="accordionEx7" role="tablist"
      aria-multiselectable="true">

      <!-- Accordion card -->
      <div class="card">

        <!-- Card header -->
        <div class="card-header rgba-stylish-strong z-depth-1 mb-1" role="tab" id="heading1">
          <a data-toggle="collapse" data-parent="#accordionEx7" href="#collapse1" aria-expanded="true"
            aria-controls="collapse1">
            <h5 class="mb-0 white-text text-uppercase font-thin">
              Inforica Inc. <i class="fas fa-angle-down rotate-icon"></i>
            </h5>
          </a>
        </div>

        <!-- Card body -->
        <div id="collapse1" class="collapse show" role="tabpanel" aria-labelledby="heading1"
          data-parent="#accordionEx7">
          <div class="card-body mb-1 ">
            <ul>Software Developer
              <li>Creating reusable components, Routing, Services and APIs in Angular</li>
              <li>Bug fixing and supporting code in C# .Net Core and Angular on ASP .Net Zero Framework</li>
              <li>Creating Stored Procedures, Tables and Views on SQL Server</li>
              <li>Development with HTML, CSS, Typescript, Bootstrap, JSON</li>
              <li>Maintaining Virtual Machines, Web Apps and working with Kusto Queries on Microsoft Azure</li>
              <li>Working with BPM tools to create data integration workflows</li>
              <li>Source code management using Team Foundation Server</li>
              <li>Technical analysis and determining requirementsSolving complex code problems and POC testing</li>
              </ul>
          </div>
        </div>
      </div>
      <!-- Accordion card -->

      <!-- Accordion card -->
      <div class="card">

        <!-- Card header -->
        <div class="card-header rgba-stylish-strong z-depth-1 mb-1" role="tab" id="heading2">
          <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx7" href="#collapse2"
            aria-expanded="false" aria-controls="collapse2">
            <h5 class="mb-0 white-text text-uppercase font-thin">
              Smart Employee Benefits Inc./SEB Administrative Inc. <i class="fas fa-angle-down rotate-icon"></i>
            </h5>
          </a>
        </div>

        <!-- Card body -->
        <div id="collapse2" class="collapse" role="tabpanel" aria-labelledby="heading2"
          data-parent="#accordionEx7">
          <div class="card-body mb-1 ">
            <ul>Web Developer
              <li>Developing dynamic and static web pages using Javascript, HTML</li>
              <li>Developing, designing and maintaining multiple client websites</li>
              <li>Designing webpage layout using Bootstrap and CSS</li>
              <li>Working with various CMS like DNN, Wordpress, Shopify and Squarespace</li>
              <li>Working with reusable components in Angular 4, React</li>
              <li>Hosting Web Apps on Microsoft Azure and performing operations in SQL Server</li>
              <li>Solving code problems and researching current design trends</li>
              <li>Designing graphics, animations and manipulating digital photographs</li>
              <li>Training client's staff, providing technical support</li>
              </ul>
          </div>
        </div>
      </div>
      <!-- Accordion card -->
    </div>
    <!--/.Accordion wrapper-->

  </div>
  <!-- Grid column -->

</div>
<!-- Grid row -->
</div>