import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import * as firebase from 'firebase/app';
// import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
// import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
// import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { SkillsComponent } from './skills/skills.component';
import { ContactComponent } from './contact/contact.component';
import { ErrorComponent } from './error/error.component';
import { NavbarComponent } from './navbar/navbar.component';
import { BackgroundComponent } from './background/background.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    SkillsComponent,
    ContactComponent,
    ErrorComponent,
    NavbarComponent,
    BackgroundComponent
  ],
  imports: [
    // AngularFireModule,
    // AngularFirestoreModule,
    // AngularFireFunctionsModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [
    // AngularFirestore,
              { //to make recaptcha available globally
                provide: RECAPTCHA_SETTINGS,
                useValue: { siteKey: environment.recaptchaSiteKey, theme:"dark" } as RecaptchaSettings,
              }
            ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(){         
    firebase.initializeApp(environment.firebaseConfig);
  }
}
