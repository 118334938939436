<div class="content" [style.height.px]="intViewportHeight">
<div class="row">
    <div class="col-md-3 col-sm-12 skill-section">
        <div class="row">
            <div class="col">
                <i class="fas fa-tv" data-toggle="tooltip" data-placement="top" title="" data-original-title="Front End Skills" style="transform: rotateY(360deg);"></i>
            </div>
        </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 99%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> HTML5</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 99%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> CSS3</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> JSON</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 95%;" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> Bootstrap</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 90%;" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill">Angular 2+</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 90%;" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> React.js</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> Redux</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 99%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> JavaScript</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 95%;" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> TypeScript</span>
                </div>
            </div>
    </div>

    <div class="col-md-3 col-sm-12 skill-section">
        <div class="row">
            <div class="col">
                <i class="fas fa-file-code" data-toggle="tooltips" data-placement="top" title="" data-original-title="Back End Skills" style="transform: rotateY(360deg);"></i>
            </div>
        </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 99%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill">NodeJs</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 70%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> ExpressJS</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 70%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> .Net Core</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> VSTS</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> GitHub</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 70%;" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> Bit Bucket</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> RestAPIs</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 90%;" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> OOP</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 70%;" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> Adobe Suite</span>
                </div>
            </div>
    </div>

    <div class="col-md-3 col-sm-12 skill-section">
        <div class="row">
            <div class="col">
                <i class="fas fa-database" data-toggle="tooltip" data-placement="top" title="" data-original-title="Database Skills" style="transform: rotateY(360deg);"></i>
            </div>
        </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 90%;" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill">MongoDB</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> SQL Server</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 90%;" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> MySQL</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill">Firebase</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 99%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> Cloud Functions</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 70%;" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> Firestore</span>
                </div>
            </div>
    </div>

    <div class="col-md-3 col-sm-12 skill-section">
        <div class="row">
            <div class="col">
                <i class="fas fa-tools" data-toggle="tooltip" data-placement="top" title="" data-original-title="Other Skills" style="transform: rotateY(360deg);"></i>
            </div>
        </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 99%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill">Unit Testing</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 99%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> Test Driven Development</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 70%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> Ionic</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 99%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> GitHub</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 80%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> Google Cloud Platform</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 70%;" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> Microsoft Azure</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 99%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill">REST APIs</span>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 99%;" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill"> Agile methodology</span>
                </div>
            </div>
    </div>
</div>
</div>