
    <div class="header row">
        <div style="text-align: left;" class="col">
            <span ><a href="https://www.linkedin.com/in/gillgurtej/" target="_blank">Linkedin</a></span>
            <!-- &nbsp;|&nbsp;
            <span ><a href="https://github.com/gurtejgill" target="_blank">Github</a></span> -->
            &nbsp;<span class="material-icons copyright"> copyright</span>
        </div>
        <div *ngIf="imageurl" style="text-align: right;" class="col">
            <span >Image by <a [href]="urlToUnsplash" target="_blank">{{imageOwner}}</a></span>
        </div>
    </div>
    <div>
        <div style="text-align: right;" class="col">
            <span class="note">Reload for new wallpaper</span>
        </div>
    </div>
    <div *ngIf="imageurl" class="img-container">
    <img [src]="imageurl" onload="this.className='animated-background'" 
                            [style.min-height.px]="intViewportHeight"
                            [style.min-width.px]="intViewportWidth">
    </div>
