import { Component, OnInit } from '@angular/core';
import * as myGlobals from 'globals';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  intViewportHeight: number;

  constructor() { }

  ngOnInit(): void {
    this.intViewportHeight = myGlobals.intViewportHeight;
  }

}
