import * as firebase from 'firebase/app';
import 'firebase/functions';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  constructor() { }

  sendToken(token) {
    var sendRecaptcha = firebase.functions().httpsCallable('sendRecaptcha');
    var result = sendRecaptcha({ token: token, testAttr: 'test' }).then(function (result) {

      return result;
    });
    return result;
  }
}
