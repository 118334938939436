import { Component, OnInit } from '@angular/core';
import { WallpaperService } from '../wallpaper.service';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.css']
})
export class BackgroundComponent implements OnInit {
  imageurl: any;
  urlToUnsplash: any;
  imageOwner: any;
  intViewportHeight: number;
  intViewportWidth: number;

  constructor(
    private wallpaperService: WallpaperService
    ) { 
  }

  ngOnInit(): void {
    this.getWallpaper();
    this.intViewportHeight = window.innerHeight;
    this.intViewportWidth = window.innerWidth;
  }

    //function to get wallpaper from server
    getWallpaper() {
      this.wallpaperService.getImage()
        .then(res => {
          //console.log("AppComponentWallpaper", res);
          this.imageurl = res.data.urls.regular;
          this.urlToUnsplash = res.data.links.html;
          this.imageOwner = res.data.user.name;
          //console.log("image url: ", this.imageurl)
        })
        .catch(err => {
          //console.log("error: ", err)
        }
        );
    }

}
